import {Component, OnInit} from '@angular/core';
import {NbDialogService, NbGlobalPhysicalPosition, NbToastrService} from "@nebular/theme";
import {GlobalService} from "../../services/global.service";
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import * as moment from 'moment';
import {GlobalConfirmDialogComponent} from "../global-confirm-dialog/global-confirm-dialog.component";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'ngx-global-upsert',
  templateUrl: './global-upsert.component.html',
  styleUrls: ['./global-upsert.component.scss']
})
export class GlobalUpsertComponent implements OnInit {
  baseUrl = environment.apiBaseUrl;
  websiteUrl = environment.websiteUrl;
  position = {lat: 52.20890484219172, lng: 5.490007244391861};
  min = new Date();

  action = 'add';
  title = 'Upsert';
  route: string;
  id: string;
  data: any;
  form: UntypedFormGroup;
  fields = [];
  fieldTypes: any[] = null;
  physicalPositions = NbGlobalPhysicalPosition;
  titles = [];
  dataPreLoaded = true;

  constructor(
    private _route: ActivatedRoute,
    protected _router: Router,
    private _formBuilder: UntypedFormBuilder,
    private _dialogService: NbDialogService,
    protected _service: GlobalService,
    private _toastrService: NbToastrService
  ) {  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      if (params['id']) {
        this.id = params['id'];
        this.loadData(params['id']);
        this.action = 'edit';
      } else if (!this.fieldTypes) {
        this._service.getAll({limit: 1}).subscribe((data) => {
          if (data) {
            this.data = data[0];
            this.initForm();
            this.data = {};
          }
        });
      } else {
        this.initForm();
      }
    });
  }

  loadData(id: string) {
    if (id) {
      this._service.get(id).subscribe((data) => {
          if (data) {
            this.data = data;
            this.initForm();
          }
        }
      );
    }
  }

  initForm() {
    const self = this;

    if (self.dataPreLoaded) {
      this.dynamicInitForm();
    } else {
      setTimeout(function () {
        self.initForm();
      }, 500)
    }
  }

  dynamicInitForm() {
    /**
     * Form init
     */
    const formConfig = {};
    if (this.fieldTypes) {
      this.fieldTypes.forEach(fields => {
        fields.forEach(key => {
          if (key.name === 'gps') {
            formConfig['lat'] = ['', []];
            formConfig['lng'] = ['', []];
          } else if (key.type === 'files') {
            formConfig[key.name] = this._formBuilder.array([]);
          } else if (key.type === 'checkbox') {
            formConfig[key.name] = [(this.action === 'edit' ? (this.data[key.name] === 'y') : (key.default ? key.default : '')), []];
          }  else if (key.type === 'date') {
            if (this.data[key.name]) {
              this.data[key.name] = new Date(this.data[key.name]);
            }

            formConfig[key.name] = [(this.action === 'edit' ? this.data[key.name] : (key.default ? key.default : '')), []];
          } else {
            formConfig[key.name] = [(this.action === 'edit' ? this.data[key.name] : (key.default ? key.default : '')), []];
          }
        });
      });
    } else {
      Object.keys(this.data).forEach(key => {
        if (key === 'startDate' || key === 'endDate') {
          formConfig[key] = [moment(this.data[key]).format('YYYY-MM-DDTHH:mm'), []];
        } else {
          formConfig[key] = [(this.action === 'edit' ? this.data[key] : ''), []];
        }
      })
    }
    this.form = this._formBuilder.group(formConfig);

    if (this.fields.length === 0 && this.data) {
      this.fields = Object.keys(this.data);
    }

    this.formLoadCompleted();
  }

  formLoadCompleted() {

  }

  save() {
    // if (this.form.valid) {
      if (this.action === 'add') {
        this._service.insert(this.form.value).subscribe(() => {
          this._toastrService.show(`${this.route} toegevoegd`, 'Opgeslagen', {
            position: this.physicalPositions.TOP_LEFT,
            status: 'success'
          });
        });
      } else {
        this.fieldTypes.forEach((fields, index) => {
          fields.forEach(key => {
            if (key.type === 'checkbox') {
              this.form.value[key.name] = (this.form.value[key.name] === true ? 'y' : 'n');
            }
            if (key.type === 'textarea') {
              this.form.value[key.name] = (this.form.value[key.name]  ? this.form.value[key.name] : '');
            }
          });
        })
        const data = this.form.value;

        this._service.update(this.id, data).subscribe(() => {
          this._toastrService.show(`${this.route} aangepast`, 'Opgeslagen', {
            position: this.physicalPositions.TOP_LEFT,
            status: 'success'
          })

//           if (this.form.controls['files'] && this.form.get('files').value && this.form.controls['files'].value.length > 0) {
//             console.log(this.form.get('files').value);
//             const formData = new FormData();
//             this.form.get('files').value.forEach((file) => {
//               formData.append('files[]', file);
//             });
// console.log(JSON.stringify(formData));
//             this._service.upload(this.id, formData).subscribe(() => {
//               this._toastrService.show(`${this.route} documenten geupload`, 'Opgeslagen', {
//                 position: this.physicalPositions.TOP_LEFT,
//                 status: 'success'
//               });
//             });
//           }
        });
      }
    // }
  }

  delete() {
    this._dialogService.open(GlobalConfirmDialogComponent, {
      context: {
        title: 'Verwijderen?',
        message: 'Weet je het zeker?',
      }
    }).onClose.subscribe(res => {
      if (res) {
        this._service.delete(this.id).subscribe(() => {
          this._toastrService.show(`${this.route} verwijderd`, 'Verwijderd', {
            position: this.physicalPositions.TOP_LEFT,
            status: 'success'
          })
          this._router.navigate([`/dashboard/${this.route}`]);
        });
      }
    });
  }


  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.form.patchValue({
        files: event.target.files[0]
      });
    }
  }

}
